import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { breakpoints, colors, fonts, spacing } from 'utils/styles'
import ProductListing from 'components/ProductListing'
import SEO from 'components/shared/SEO'

import './home.scss'

const ThankYouPage = () => (
    <div/>
)


export default ({ data, location }) => {
  return (
    <>
      <SEO />
      <ThankYouPage data={data} />
    </>
  )
}